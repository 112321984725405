import * as React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import siteconfig from '../../siteconfig.esm'

const PageHeader = (props) => {
  const { crumbs } = props

  return siteconfig.setup.showBreadcrumb
    ? (
      <Breadcrumb className="breadcrumb-container">
        {crumbs.map((crumb, i) => {
          return crumb.link
            ? <li className={`breadcrumb-item ${i === crumbs.length - 1 ? 'active' : ''}`} key={i} aria-current={crumbs.length - 1 === i}>
                <a href={crumb.link}>{crumb.label}</a>
              </li>
            : <li className={`breadcrumb-item disabled ${i === crumbs.length - 1 ? 'active' : ''}`} key={i} aria-current={crumbs.length - 1 === i}>{crumb.label}</li>
        })}
      </Breadcrumb>
    )
  : (<></>)
}

export default PageHeader
